import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'index.css';

function importEntry() {
  if (process.env.REACT_APP_ENTRY === 'admin') {
    return import('admin/AdminApp');
  }
  return import('App');
}

importEntry().then(({ default: App }) => {
  ReactDOM.render(<App />, document.getElementById('app-root'));
});

serviceWorkerRegistration.register();
